import React from "react"

const Row = ({ children }) => {
    return (
        <div className="row block lg:flex items-center justify-content my-3">
            {children}
        </div>
    )
}

const Col = ({ children }) => {
    return (
        <div className="flex-1 p-2 pl-3 pr-3">
            {children}
        </div>
    )
}

const BgImg = (prop) => {
    const height = prop.height || "400"
    const style = {
        height: `${height}px`,
        backgroundImage:`url('${prop.src}')`,
    }
    return (
        <div style={style} className="bg-img"></div>
    )
}

export { Row, Col, BgImg }
